<template>
<div>
    <b-container fluid="sm" class="mt-5">
      <b-row>
        <b-col sm="5" offset-sm="3">
                <b-card :img-src="require('../assets/logo.png')" img-alt="Card image" img-top header-tag="header" header-bg-variant="secondary" header-text-variant="white" header-class="header">
      <template #header>
          <h4 class="mb-0">Connexion</h4>
       </template>
            <b-card-body>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(login)" >

                <b-alert variant="danger" :show="message != ''" class="mt-3 mb-3">{{message}}</b-alert>

                <text-input v-model="user.username" label="Identifiant" type="text" rules="required|min:3"></text-input>
                <text-input v-model="user.password" label="Mot de passe" type="password" rules="required|min:3"></text-input> 

                <div class="d-flex justify-content-end">
                <b-button type="submit" variant='primary'>Se connecter<b-spinner v-if="loading" small type="border"></b-spinner></b-button>
                </div>
            </b-form>
        </validation-observer>
        </b-card-body>

    <!-- <b-row v-for="i in 1000" :key="i">
        <label for="img">Image {{i}}</label>
        <b-card-img id="img" :src="pic(i)" alt="Image" class="rounded-0"></b-card-img>
    </b-row> -->
  </b-card>
        </b-col>
      </b-row>

</b-container>
</div>
  
</template>

<script>
import User from '../models/user';
import TextInput from '../components/TextInput.vue'

export default {
  name: 'Login',
  components: { TextInput},
  data :() => ({
    user: new User('', '',''),
    loading: false,
    message: ''
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  methods: {
      pic() {
        //return "https://picsum.photos/400/400/?image=" + i
        return require('../assets/logo.png')
      },
    login() {
        this.loading = true;
        this.$store.dispatch('login', this.user).then(
            () => {
                this.$router.push('/dossiers');
            },
            error => {
                this.loading = false;
                if(error.response.status == 403) {
                  this.message = 'Login ou mot de passe incorrect'
                }
                else {
                  this.message = 'Erreur'
                }
            }
        )
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit (evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    }
  }
};
</script>

<style scoped>

.card-img-top {
  margin: 15px 0px 15px 0px;
}
</style>