<template>
  <div class="container" fluid="sm">  
    <h3 class="mt-4">Dossiers</h3>

    <!-- <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-row>
        <b-col>
          <b-form-group
            label="Rechercher un dossier"
            label-for="search"
            invalid-feedback="min 3 caratères"
            required
            :state="searchState"
          >
          <b-input-group-append>
            <b-form-input id="search" v-model="keyword" @keyup="searchDossier()"></b-form-input>
          </b-input-group-append>
          </b-form-group>
        </b-col>
      </b-form-row>
    </form> -->


    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>
        <b-card v-for="dossier in filtreDossiers" :key="dossier.id_dossier" header-bg-variant="secondary" class="mb-3">
          <template v-slot:header>
            <div class="text-white">
              <h6 v-if="dossier" class="mb-0 align-items-center">{{dossier.titre_dossier}}</h6>
              <small v-if="dossier" class="mb-0 align-items-center">{{dossier.code_dossier}}</small>
              <small v-if='complet(dossier)' class="ml-2  text_wrap"><b-badge variant="success">Dossier complet</b-badge></small>
              <div v-if='!complet(dossier)' class="d-flex w-100 justify-content-start">
                <small v-if='dossier.statut_attente > 0' class="mr-1 text_wrap"><b-badge variant="warning">{{ dossier.statut_attente + ' document(s) en attente'}}</b-badge></small>
                <small v-if='dossier.statut_incomplet > 0' class="mr-1 text_wrap"><b-badge variant="danger">{{ dossier.statut_incomplet + ' document(s) incomplet(s)'}}</b-badge></small> 
              </div>
            </div>
          </template>

          <b-card-body>
              <div class="d-flex w-100 justify-content-between">
                Votre dossier est à l'étape {{ dossier.libelle_workflow_statut}} depuis le {{dossier.date_workflow_statut}}
              </div>
                <p class="mb-1">Type de dossier : {{ dossier.libelle_type_dossier}}</p>
                <div v-if='donneurOrdre'>
                  <p class="mb-1">Raison sociale : {{ dossier.raison_sociale}}</p>
                  <p class="mb-1">Nom : {{ dossier.nom_contact}}</p>
                  <p class="mb-1">Prénom : {{ dossier.prenom_contact}}</p>
                  <p class="mb-1">Adresse : {{ dossier.adresse}}</p>
                  <p class="mb-1">Code Postal : {{ dossier.code_postal}}</p>
                  <p class="mb-1">Ville : {{ dossier.ville}}</p>
                </div>
                <!-- <p class="mb-1">Email : {{ dossier.email}}</p> -->

            </b-card-body> 
            <template v-slot:footer>
                <div class="d-flex w-100 justify-content-end">
                  <b-button v-if="Number(dossier.total_check) > 0" @click='selectionDossier(dossier)' :to="`/dossier/${dossier.id_dossier}`" variant="primary" size="sm">Détails</b-button>
                </div>
            </template> 
        </b-card>    
    </b-skeleton-wrapper>
      <b-button v-if='!loading' @click="loadMore()" variant="primary" block class="mb-5">Afficher plus
        <b-spinner v-if='loadingMore' small></b-spinner>
      </b-button>
  </div>
</template>

<script>

import DossierService from '../services/DossierService'

  export default {
    data() {
      return {
        dossiers : [],
        loading: false,
        loadingMore : false,
        keyword : '',
        searchState : null,
        filtreDossiers : [],
        startRecord :0,
        pageSize : 20
      }
    },
    computed : {
      idOrganisation() {
        return this.$store.getters.user.id_organisation
      },
      user() {
        return this.$store.state.auth.user
      },
      donneurOrdre() {
        return this.$store.state.auth.user.categorie_membre == 3
      }
    },
    methods : {
      loadDossier(){
        let status = 999 //
        return DossierService.GetListe(this.user.id_user_membre, status, this.startRecord, this.pageSize).then(result => {
          this.loading = false;
          if(result.data.statut == 'OK') {

            this.dossiers = [...this.dossiers, ...result.data.liste] 
            this.filtreDossiers = JSON.parse(JSON.stringify(this.dossiers))
            this.startRecord += this.pageSize
            //console.log(this.dossiers)
            return Promise.resolve()
          }
        }).catch(err => {
          this.loading = false
          console.log(err)
          return Promise.resolve()
        })
      },
      searchDossier(){
        if(this.keyword.length < 3) {
          this.filtreDossiers = JSON.parse(JSON.stringify(this.dossiers))
          return
        }

        this.filtreDossiers = this.dossiers.filter(x=> x.titre_dossier.toUpperCase().includes(this.keyword.toUpperCase()))
      },
      complet(dossier) {
        return Number(dossier.statut_attente) == 0 && Number(dossier.statut_incomplet) == 0
      },
      selectionDossier(dossier) {
        this.$store.dispatch('selectionDossier', dossier)
      },
      loadMore() {
        this.loadingMore = true
        this.loadDossier()
        .then(() => { 
          this.loadingMore = false
        })
      }
    },
    mounted() {
      this.loading = true
      this.loadDossier()
    }
  }
</script>


<style>

</style>